@use '@angular/material' as mat;

@mixin color-selector-component-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $accentPalette: map-get($theme, accent);
  $primary: mat.get-color-from-palette($primaryPalette);
  $accent: mat.get-color-from-palette($accentPalette, 50);
  //custom color imports
  $orange: map-get($theme, orange-states);
  $orange1: map-get($theme, orange1-states);
  $orange2: map-get($theme, orange2-states);
  $orange3: map-get($theme, orange3-states);
  $red: map-get($theme, red-states);
  $red1: map-get($theme, red1-states);
  $red2: map-get($theme, red2-states);
  $red3: map-get($theme, red3-states);
  $red4: map-get($theme, red4-states);
  $red5: map-get($theme, red5-states);
  $blue1: map-get($theme, blue1-states);
  $blue2: map-get($theme, blue2-states);
  $blue3: map-get($theme, blue3-states);
  $blue4: map-get($theme, blue4-states);
  $green: map-get($theme, green-states);
  $green1: map-get($theme, green1-states);
  $green2: map-get($theme, green2-states);
  $green3: map-get($theme, green3-states);
  $green4: map-get($theme, green4-states);
  $green5: map-get($theme, green5-states);
  $green6: map-get($theme, green6-states);
  $purps1: map-get($theme, purps1-states);
  $purps2: map-get($theme, purps2-states);
  $purps3: map-get($theme, purps3-states);
  $purps4: map-get($theme, purps4-states);
  $purps5: map-get($theme, purps5-states);
  $gray: map-get($theme, gray-states);
  $gray1: map-get($theme, gray1-states);
  $gray2: map-get($theme, gray2-states);
  $navy: map-get($theme, navy-states);
  $light-blue: map-get($theme, light-blue-states);

  .more-color-btn {
    color: $primary;
  }

  // turn this on to override the custom contrast of each of the colors below
  // .role-icon {
  // color: mat.get-contrast-color-from-palette($primaryPalette, 500);
  // }
  .blue {
    background-color: $primary;
    color: mat.get-contrast-color-from-palette($primaryPalette, 500);
  }

  .blue-foreground {
    color: $primary;
  }

  .green {
    background-color: map-get($green, base);
    color: map-get($green, contrast);
  }

  .green-foreground {
    color: map-get($green, base);
  }

  .gray {
    background-color: map-get($gray, base);
    color: map-get($gray, contrast);
  }

  .gray-foreground {
    color: map-get($gray, base);
  }

  .light-blue {
    background-color: map-get($light-blue, base);
    color: map-get($light-blue, contrast);
  }

  .light-blue-foreground {
    color: map-get($light-blue, base);
  }

  .red {
    background-color: map-get($red, base);
    color: map-get($red, contrast);
  }

  .red-foreground {
    color: map-get($red, base);
  }

  .navy {
    background-color: map-get($navy, base);
    color: map-get($navy, contrast);
  }

  .navy-foreground {
    color: map-get($navy, base);
  }

  .orange {
    background-color: map-get($orange, base);
    color: map-get($orange, contrast);
  }

  .orange-foreground {
    color: map-get($orange, base);
  }

  .red1 {
    background-color: map-get($red1, base);
    color: map-get($red1, contrast);
  }

  .red1-foreground {
    color: map-get($red1, base);
  }

  .red2 {
    background-color: map-get($red2, base);
    color: map-get($red2, contrast);
  }

  .red2-foreground {
    color: map-get($red2, base);
  }

  .red3 {
    background-color: map-get($red3, base);
    color: map-get($red3, contrast);
  }

  .red3-foreground {
    color: map-get($red3, base);
  }

  .red4 {
    background-color: map-get($red4, base);
    color: map-get($red4, contrast);
  }

  .red4-foreground {
    color: map-get($red4, base);
  }

  .red5 {
    background-color: map-get($red5, base);
    color: map-get($red5, contrast);
  }

  .red5-foreground {
    color: map-get($red5, base);
  }

  .orange1 {
    background-color: map-get($orange1, base);
    color: map-get($orange1, contrast);
  }

  .orange1-foreground {
    color: map-get($orange1, base);
  }

  .orange2 {
    background-color: map-get($orange2, base);
    color: map-get($orange2, contrast);
  }

  .orange2-foreground {
    color: map-get($orange2, base);
  }

  .orange3 {
    background-color: map-get($orange3, base);
    color: map-get($orange3, contrast);
  }

  .orange3-foreground {
    color: map-get($orange3, base);
  }

  .blue1 {
    background-color: map-get($blue1, base);
    color: map-get($blue1, contrast);
  }

  .blue1-foreground {
    color: map-get($blue1, base);
  }

  .blue2 {
    background-color: map-get($blue2, base);
    color: map-get($blue2, contrast);
  }

  .blue2-foreground {
    color: map-get($blue2, base);
  }

  .blue3 {
    background-color: map-get($blue3, base);
    color: map-get($blue3, contrast);
  }

  .blue3-foreground {
    color: map-get($blue3, base);
  }

  .blue4 {
    background-color: map-get($blue4, base);
    color: map-get($blue4, contrast);
  }

  .blue4-foreground {
    color: map-get($blue4, base);
  }

  .green1 {
    background-color: map-get($green1, base);
    color: map-get($green1, contrast);
  }

  .green1-foreground {
    color: map-get($green1, base);
  }

  .green2 {
    background-color: map-get($green2, base);
    color: map-get($green2, contrast);
  }

  .green2-foreground {
    color: map-get($green2, base);
  }

  .green3 {
    background-color: map-get($green3, base);
    color: map-get($green3, contrast);
  }

  .green3-foreground {
    color: map-get($green3, base);
  }

  .green4 {
    background-color: map-get($green4, base);
    color: map-get($green4, contrast);
  }

  .green4-foreground {
    color: map-get($green4, base);
  }

  .green5 {
    background-color: map-get($green5, base);
    color: map-get($green5, contrast);
  }

  .green5-foreground {
    color: map-get($green5, base);
  }

  .green6 {
    background-color: map-get($green6, base);
    color: map-get($green6, contrast);
  }

  .green6-foreground {
    color: map-get($green6, base);
  }

  .purps1 {
    background-color: map-get($purps1, base);
    color: map-get($purps1, contrast);
  }

  .purps1-foreground {
    color: map-get($purps1, base);
  }

  .purps2 {
    background-color: map-get($purps2, base);
    color: map-get($purps2, contrast);
  }

  .purps2-foreground {
    color: map-get($purps2, base);
  }

  .purps3 {
    background-color: map-get($purps3, base);
    color: map-get($purps3, contrast);
  }

  .purps3-foreground {
    color: map-get($purps3, base);
  }

  .purps4 {
    background-color: map-get($purps4, base);
    color: map-get($purps4, contrast);
  }

  .purps4-foreground {
    color: map-get($purps4, base);
  }

  .purps5 {
    background-color: map-get($purps5, base);
    color: map-get($purps5, contrast);
  }

  .purps5-foreground {
    color: map-get($purps5, base);
  }

  .gray1 {
    background-color: map-get($gray1, base);
    color: map-get($gray1, contrast);
  }

  .gray1-foreground {
    color: map-get($gray1, base);
  }

  .gray2 {
    background-color: map-get($gray2, base);
    color: map-get($gray2, contrast);
  }

  .gray2-foreground {
    color: map-get($gray2, base);
  }
}
