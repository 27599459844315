@use '@angular/material' as mat;

@mixin delegation-list-component-theme($theme) {
  $errorStates: map-get($theme, error-states);
  $error: map-get($errorStates, base);

  .delegation-main {
    .unsaved-warning {
      color: $error;
    }

    .role-name-div {
      .title-text {
        margin-bottom: 0;
      }
    }
  }
}
