@use '@angular/material' as mat;
@use '@psu/theme-lib/mdc/m2' as psu-theme-lib;
@use '@psu/components' as components-theme;
@use 'theme.scss' as theme-constants;
@use './app/theming/theming' as app-theme;
@use '@psu/palette/nittany-navy' as palette;
$customColorMap: map-merge(
  palette.$status-colors,
  (
    orange: #ff7e05,
    orange1: #b85e09,
    orange2: #ce4900,
    orange3: #e03302,
    red: #e71c1c,
    red1: #720e0e,
    red2: #63410b,
    red3: #79370f,
    red4: #bc1b75,
    red5: #e20b89,
    blue1: #13468f,
    blue2: #0f4f79,
    blue3: #157fa0,
    blue4: #396cd9,
    blue5: #1e407c,
    green: #008755,
    green1: #0a4946,
    green2: #13883a,
    green3: #128574,
    green4: #007700,
    green5: #618002,
    green6: #072801,
    purps1: #5d138f,
    purps2: #85138f,
    purps3: #a0158f,
    purps4: #c90be2,
    purps5: #9e3def,
    gray: #9b9b9b,
    gray1: #54585a,
    gray2: #314d64,
    navy: #314d64,
    light-blue: #009cde,
    light-blue2: #bcdaef,
    psu-matgray50: #e5e6e7,
    psu-matgray500: #9e9e9e,
  )
);

$app-typography: psu-theme-lib.generate-material-typography(
  theme-constants.$title-font,
  theme-constants.$body-font,
  theme-constants.$title-weight-bold,
  theme-constants.$body-weight-bold,
  theme-constants.$title-weight-regular,
  theme-constants.$body-weight-regular
);

$app-theme: psu-theme-lib.generate-material-light-theme(
  theme-constants.$primary-palette,
  theme-constants.$secondary-palette,
  theme-constants.$error-palette,
  $app-typography,
  $customColorMap
);

@include mat.core();
@include mat.all-component-typographies($app-typography);
@include mat.all-component-themes($app-theme);

// Include this mixin for material specific adjustments/overrides based on supplied variables
@include psu-theme-lib.material-theme-adjustments(
  $app-theme,
  theme-constants.$title-weight-bold,
  theme-constants.$body-weight-bold,
  theme-constants.$title-weight-regular,
  theme-constants.$body-weight-regular
);

@include app-theme.starfish-app-theme($app-theme);
@include components-theme.uic-components-theme($app-theme);

table {
  width: 100%;
}

.mat-mdc-header-row:hover {
  background-color: inherit !important;
}

.hand-pointer {
  cursor: pointer;
}

.end-align-flex {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
}

.center-align-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.display-flex {
  display: flex;
}

.mat-autocomplete-width {
  width: 100% !important;
}

.mat-roles-search {
  width: 450px;
}

.mat-roles-search:not(.mat-focused) mat-label {
  color: rgba(0, 0, 0, 0.87);
}

.mat-roles-search .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.87);
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.content-area {
  background-color: map-get(map-get($app-theme, background), app-bar);
}

// Snack bar background colors for psu-angular-common NotificationService.
snack-bar-container.error {
  background-color: mat.get-color-from-palette(theme-constants.$error-palette);
}

snack-bar-container.warn {
  background-color: map-get(map-get($app-theme, alert-states), base);
}

snack-bar-container.info {
  background-color: mat.get-color-from-palette(theme-constants.$secondary-palette, 600);
}

snack-bar-container.success {
  background-color: map-get(map-get($app-theme, success-states), base);
}

// Material spec says button text should be uppercase.
.mat-mdc-button,
.mat-mdc-raised-button {
  text-transform: uppercase;
}

// Use the red theme for mat-input errors.
mat-form-field.ng-touched.ng-invalid,
div.ng-invalid mat-form-field.ng-touched {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-label {
    color: mat.get-color-from-palette(theme-constants.$error-palette);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    border-top-width: 2px;
    border-color: mat.get-color-from-palette(theme-constants.$error-palette);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-ripple {
    background-color: mat.get-color-from-palette(theme-constants.$error-palette);
  }

  mat-hint {
    color: mat.get-color-from-palette(theme-constants.$error-palette);
  }
}

.error_message {
  color: #c02926;
  background-color: #fffdfd;
  font-weight: bold;
  border: 1px solid #c02926;
  padding: 5px;
}

mat-list-item:hover {
  background-color: map-get(map-get($app-theme, psu-matgray50-states), base);
}

.failed-styling {
  color: #c02926;
  background-color: #ffffff;
  border: 1px solid #c02926;
  padding: 5px;
}

//////////// Starting a post-angular-cli section. Everything above should be audited
.spaced-cards {
  mat-card {
    margin-bottom: 16px;
  }
}

.card-table {
  h1 {
    padding: 0 16px;
  }

  .starfish-filter {
    padding-left: 16px;
    padding-right: 16px;
  }

  .starfish-welcome {
    padding-left: 16px;
    padding-right: 16px;
  }

  mat-paginator {
    border-radius: 0 0 8px 8px;
  }
}

mat-card {
  border-radius: 8px !important;
}

.add-new-contained-btn {
  margin-right: 16px;
  margin-top: 8px;
}
