@use '@angular/material' as mat;

@mixin icon-selector-component-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $accentPalette: map-get($theme, accent);
  $primary: mat.get-color-from-palette($primaryPalette);
  $accent: mat.get-color-from-palette($accentPalette, 50);

  .more-icon-btn {
    color: $primary;
  }
}
