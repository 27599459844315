@use '@angular/material' as mat;

@mixin assignment-form-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $primary: mat.get-color-from-palette($primaryPalette);
  $successStates: map-get($theme, success-states);
  $errorStates: map-get($theme, error-states);
  $success: map-get($successStates, base);
  $error: map-get($errorStates, base);

  .sticky-all-saved {
    background-color: gray !important;
    animation: none !important;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(gray, 0);
    }

    100% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(gray, 0);
    }
  }
}
