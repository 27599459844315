@use '@angular/material' as mat;

@mixin main-app-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $accentPalette: map-get($theme, accent);
  $accent: mat.get-color-from-palette($accentPalette);
  $primary: mat.get-color-from-palette($primaryPalette);
  $dark-primary: mat.get-color-from-palette($primaryPalette, 900);
  $psu-link: mat.get-color-from-palette($primaryPalette, A200);
  $primaryStates: map-get($theme, primary-states);
  $primaryContrast: mat.get-contrast-color-from-palette($primaryPalette, 900);
  $bodyBoldWeight: 600; // Change depending on body font used

  .primary-color {
    background-color: $primary;
    color: mat.get-contrast-color-from-palette($primaryPalette, 500);
  }

  header {
    background: $dark-primary;
  }

  a {
    color: $psu-link;
    cursor: pointer;
    font-weight: $bodyBoldWeight;
    text-decoration: underline;
  }

  .app-component-main {
    --mat-sidenav-container-background-color: #{$dark-primary};

    .mat-toolbar {
      --mat-toolbar-container-background-color: #{$dark-primary};

      .starfish-name-heading {
        border: 3px solid mat.get-color-from-palette($primaryPalette, 200);
      }

      .menu-button {
        --mat-mdc-button-ripple-color: #{rgba($primaryContrast, 0.1)};
        --mat-mdc-button-persistent-ripple-color: #{$primaryContrast};
      }
    }

    .name-tag {
      background-color: $accent;
      color: mat.get-contrast-color-from-palette($accentPalette, 500);
    }
  }
}
