@mixin course-task-approval-component-theme($theme) {
  $successStates: map-get($theme, success-states);
  $errorStates: map-get($theme, error-states);
  $success: map-get($successStates, base);
  $error: map-get($errorStates, base);

  .task-approval-component {
    .confirmed-button {
      color: $success;
    }
  }
}
