@use '@angular/material' as mat;

@mixin user-table-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $primary: mat.get-color-from-palette($primaryPalette);
  $successStates: map-get($theme, success-states);
  $errorStates: map-get($theme, error-states);
  $success: map-get($successStates, base);
  $error: map-get($errorStates, base);

  // global - required as tooltips are built and added to the DOM at a higher level then this component.
  .target-tooltip {
    background-color: mat.get-color-from-palette($primaryPalette, 900) !important;
    color: mat.get-contrast-color-from-palette($primaryPalette, 900) !important;
  }
}
