@use '@angular/material' as mat;
@use 'sass:map';

@mixin header-display-component-theme($theme) {
  $emphasis-md: map.get($theme, 'emphasis-md');
  $typography: mat.get-typography-config($theme);

  .header-display-wrapper {
    font-family: #{mat.font-family($typography, 'body-2')};
    font-size: #{mat.font-size($typography, 'body-2')};
    line-height: #{mat.line-height($typography, 'body-2')};
    letter-spacing: #{mat.letter-spacing($typography, 'body-2')};

    .display-label {
      color: $emphasis-md;
    }
  }
}
