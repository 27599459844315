@use '@angular/material' as mat;
@use 'sass:map';

@mixin workflow-header-component-theme($theme) {
  $successStates: map.get($theme, success-states);
  $errorStates: map.get($theme, error-states);
  $success: map.get($successStates, base);
  $error: map.get($errorStates, base);
  $emphasis-md: map.get($theme, 'emphasis-md');
  $typography: mat.get-typography-config($theme);

  .workflow-header-component {
    .header-inner-wrapper {
      .header-column {
        font-size: #{mat.font-size($typography, 'body-2')};
        line-height: #{mat.line-height($typography, 'body-2')};
        letter-spacing: #{mat.letter-spacing($typography, 'body-2')};

        .header-label {
          color: $emphasis-md;
        }
      }
    }

    .approve-buttons {
      --mdc-outlined-button-label-text-color: #{$success};
    }

    .split-buttons-div {
      --mat-icon-color: #{$emphasis-md};

      .dc-stroked-icon-button {
        --mdc-outlined-button-label-text-color: #{$error};
      }
    }
  }
}
