@use '@angular/material' as mat;
@use 'sass:map';

@mixin autocomplete-component-theme($theme) {
  $background: map.get($theme, background);
  $light-background-color: map.get($background, card);

  .autocomplete-form-field {
    .mdc-text-field {
      background-color: $light-background-color;
    }
  }
}
