@use '@angular/material' as mat;

@mixin connected-students-summary-component-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $primary: mat.get-color-from-palette($primaryPalette);
  $successStates: map-get($theme, success-states);
  $errorStates: map-get($theme, error-states);
  $success: map-get($successStates, base);
  $error: map-get($errorStates, base);

  #add-user-role-comp-summary {
    .warning-text {
      color: $error;
    }
  }
}
