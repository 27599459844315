@use '@angular/material' as mat;
@use 'sass:map';

@mixin assigned-role-dialog-component-theme($theme) {
  $blue5States: map.get($theme, blue5-states);
  .assigned-role-dialog-container {
    .user-details-chip-content {
      color: map.get($blue5States, base) !important;
    }
  }
}
