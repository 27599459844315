@use '@angular/material' as mat;

@mixin sf-nav-list-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $accentPalette: map-get($theme, accent);
  $primary-light: mat.get-color-from-palette($primaryPalette, 200);
  $primary: mat.get-color-from-palette($primaryPalette, 700);
  $primary-dark: mat.get-color-from-palette($primaryPalette, 900);
  $accent: mat.get-color-from-palette($accentPalette, 50);
  $contrast: mat.get-contrast-color-from-palette($primaryPalette, 900);
  $typography: mat.get-typography-config($theme);

  .sf-nav-wrapper {
    --mat-expansion-header-text-font: #{mat.font-family($typography, 'subtitle-1')};
    --mat-expansion-header-text-size: #{mat.font-size($typography, 'subtitle-1')};
    --mat-expansion-header-text-line-height: #{mat.line-height($typography, 'subtitle-1')};
    --mat-expansion-header-text-tracking: #{mat.letter-spacing($typography, 'subtitle-1')};
    --mat-expansion-header-text-weight: 600;
    --mat-expansion-header-text-color: #{$contrast};

    .starfish-navigation-menu {
      background-color: $primary-dark;
      --mdc-list-list-item-leading-icon-color: #{$contrast};
      --mdc-list-list-item-hover-leading-icon-color: #{$contrast};
      --mdc-list-list-item-focus-leading-icon-color: #{$contrast};
      --mdc-list-list-item-label-text-color: #{$contrast};
      --mdc-list-list-item-hover-label-text-color: #{$contrast};
      --mdc-list-list-item-focus-label-text-color: #{$contrast};
      --mdc-list-list-item-focus-state-layer-color: #{$contrast};

      .mat-mdc-list-item {
        &:hover {
          background-color: rgba($contrast, 0.08);
        }
        &.active-link {
          --mdc-list-list-item-container-color: #{$primary};
        }
      }

      .mat-drawer-inner-container {
        background: $primary-dark;
      }

      .mat-expansion-panel {
        background-color: $primary-dark;
        --mat-expansion-container-shape: 0;

        .mat-expansion-panel-header {
          &:hover {
            background-color: rgba($contrast, 0.08);
          }

          &:focus {
            background-color: rgba($contrast, 0.12);
          }
        }
      }

      .cart-label {
        --mat-icon-color: #{$primary-light};
      }

      .cart-font {
        color: $primary-dark;
      }
    }

    .mat-expansion-indicator::after {
      color: $contrast;
    }

    .mat-mdc-slide-toggle {
      .mdc-label {
        color: $contrast;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
  }
}
