@use '@angular/material' as mat;

@mixin dynamic-role-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $primary: mat.get-color-from-palette($primaryPalette);
  $successStates: map-get($theme, success-states);
  $errorStates: map-get($theme, error-states);
  $success: map-get($successStates, base);
  $error: map-get($errorStates, base);

  .custom-file-upload {
    background-color: $primary;
    color: mat.get-contrast-color-from-palette($primaryPalette, 500);
  }

  .check-icon {
    color: $success;
  }

  .error-icon {
    color: $error;
  }

  .error-text {
    color: $error;
  }
}
