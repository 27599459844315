@use '@angular/material' as mat;
@use 'sass:map';

@mixin roles-component-theme($theme) {
  $divider: map.get($theme, foreground, divider);

  .roles-section {
    //Can be removed if stretchTabs is false
    .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header {
      border-bottom: 1px solid $divider;

      .mat-mdc-tab {
        flex-grow: 0;
      }
    }
  }
}
