@use '@angular/material' as mat;
@use 'sass:map';

@mixin edit-role-group-component-theme($theme) {
  $emphasis-md: map.get($theme, emphasis-md);

  .edit-role-group-container-class {
    --mat-expansion-header-collapsed-state-height: 72px;
    --mat-expansion-header-expanded-state-height: 72px;

    .role-description {
      color: $emphasis-md;
    }
  }
}
