@use '@angular/material' as mat;
@use 'sass:map';

@mixin role-details-form-component-theme($theme) {
  $typography: mat.get-typography-config($theme);

  .dynamic-role-form {
    .mat-mdc-radio-button {
      .mdc-label {
        margin-bottom: 0;
        font-size: mat.font-size($typography, 'body-1');
        letter-spacing: mat.letter-spacing($typography, 'body-1');
      }
    }
  }
}
