@use '@angular/material' as mat;
@use 'sass:map';

@mixin resources-component-theme($theme) {
  $black: map.get($theme, foreground, text);

  .resources-wrapper {
    .service-mat-list-item {
      --mdc-list-list-item-leading-icon-color: #{$black};
      --mdc-list-list-item-hover-leading-icon-color: #{$black};
    }
  }
}
