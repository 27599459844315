@use '@angular/material' as mat;
@use 'sass:map';

@mixin workflow-component-theme($theme) {
  $emphasis-md: map.get($theme, 'emphasis-md');
  $typography: mat.get-typography-config($theme);

  .workflow-component-wrapper {
    .role-name-div {
      .task-title {
        font-family: #{mat.font-family($typography, 'subtitle-1')};
        font-size: #{mat.font-size($typography, 'subtitle-1')};
        font-weight: #{mat.font-weight($typography, 'subtitle-1')};
        line-height: #{mat.line-height($typography, 'subtitle-1')};
        letter-spacing: #{mat.letter-spacing($typography, 'subtitle-1')};
      }

      .task-description {
        color: $emphasis-md;
        font-family: #{mat.font-family($typography, 'body-2')};
        font-size: #{mat.font-size($typography, 'body-2')};
        line-height: #{mat.line-height($typography, 'body-2')};
        letter-spacing: #{mat.letter-spacing($typography, 'body-2')};
      }
    }

    .comment-icon {
      --mat-icon-color: #{$emphasis-md};
    }
  }
}
