@use '@angular/material' as mat;

@mixin missing-users-dialog-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $primary: mat.get-color-from-palette($primaryPalette);
  $primary-light: mat.get-color-from-palette($primaryPalette, 100);

  .missing-user-dialog-content {
    .chip-color {
      background: $primary-light;
    }
  }
}
