@use '@angular/material' as mat;

@mixin directors-component-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $primary: mat.get-color-from-palette($primaryPalette);
  $successStates: map-get($theme, success-states);
  $errorStates: map-get($theme, error-states);
  $success: map-get($successStates, base);
  $error: map-get($errorStates, base);

  .area-directors-main {
    .unsaved-warning {
      color: $error;
    }

    .role-name-div {
      .title-text {
        margin-bottom: 0;
      }
    }
  }
}
