@use '@angular/material' as mat;
@use 'sass:map';

@mixin sf-dashboard-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $accentPalette: map-get($theme, accent);
  $primary-light: mat.get-color-from-palette($primaryPalette, 400);
  $primary: mat.get-color-from-palette($primaryPalette, 700);
  $accent: mat.get-color-from-palette($accentPalette, 50);
  $contrast: mat.get-contrast-color-from-palette($primaryPalette, 400);
  $typography: mat.get-typography-config($theme);
  //custom color imports
  $orange: map-get($theme, orange-states);

  .sf-dashboard-wrapper {
    .dashboard-tasklist-btn {
      --mdc-filled-button-container-color: #{map-get($orange, base)};
      --mdc-filled-button-label-text-color: #{$contrast};
    }

    .exp-panel-class {
      --mat-expansion-container-text-size: #{mat.font-size($typography, 'body-1')};
      --mat-expansion-container-text-line-height: #{mat.line-height($typography, 'body-1')};
      --mat-expansion-container-text-tracking: #{mat.letter-spacing($typography, 'body-1')};
      border: 1px solid rgba(0, 0, 0, 0.2);
    }

    a:not(.mdc-button):visited {
      color: $primary-light !important;
    }
  }
}
