@use '@angular/material' as mat;
@use 'sass:map';

@mixin user-details-component-theme($theme) {
  $light-blue2States: map.get($theme, light-blue2-states);
  $blue5States: map.get($theme, blue5-states);
  $emphasis-md: map.get($theme, 'emphasis-md');
  $typography: mat.get-typography-config($theme);

  .user-details-comp {
    .user-details-chip {
      background-color: map.get($light-blue2States, base) !important;
    }

    .user-details-chip-content {
      color: map.get($blue5States, base) !important;
    }

    .panel-title-details-delegations {
      font-size: #{mat.font-size($typography, 'subtitle-1')};
      line-height: #{mat.line-height($typography, 'subtitle-1')};
      letter-spacing: #{mat.letter-spacing($typography, 'subtitle-1')};
      font-weight: #{mat.font-weight($typography, 'subtitle-1')};
    }

    .panel-title-subdetail {
      color: $emphasis-md;
    }
  }
}
