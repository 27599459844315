@use '@angular/material' as mat;
@use 'sass:map';

@mixin theme($theme) {
  $accentPalette: map.get($theme, accent);
  $accent: mat.get-color-from-palette($accentPalette);

  .scroll-to-top-container {
    .scroll-to-top-button {
      background-color: $accent;
      color: mat.get-contrast-color-from-palette($accentPalette, 500);
    }
  }
}
