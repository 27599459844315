@use '@angular/material' as mat;
@use 'sass:map';

@mixin confirm-dialog-component-theme($theme) {
  $primaryPalette: map.get($theme, primary);
  $primary: mat.get-color-from-palette($primaryPalette, 700);
  $errorStates: map.get($theme, error-states);
  $error: map.get($errorStates, base);

  .confirm-dialog {
    .confirm-dialog-title {
      color: $primary;
    }

    .confirm-dialog-alert-icon {
      --mat-icon-color: #{$error};
    }
  }
}
