@use '@angular/material' as mat;

@mixin assign-user-component-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $primary: mat.get-color-from-palette($primaryPalette);
  $successStates: map-get($theme, success-states);
  $errorStates: map-get($theme, error-states);
  $success: map-get($successStates, base);
  $error: map-get($errorStates, base);
  $errorPalette: map-get($theme, warn);
  $error-light: mat.get-color-from-palette($errorPalette, 100);

  .assign-users-and-roles {
    .invalid-heading {
      color: $error;
    }

    .invalid-user-display {
      background-color: $error-light;
      color: $primary;
    }

    .missing-user-success {
      color: $success;
    }
  }
}
