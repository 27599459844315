@use '@angular/material' as mat;
@use 'sass:map';

@mixin typeahead-component-theme($theme) {
  $background: map.get($theme, background);
  $light-background-color: map.get($background, card);

  .typeahead-form-field {
    .mdc-text-field {
      background-color: $light-background-color;
    }

    &.with-search-button {
      .search-button {
        .mat-icon {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}
