@use '@angular/material' as mat;
@use 'sass:map';

@mixin unmapped-courses-display-component-theme($theme) {
  .unmapped-course-display-wrapper {
    .mapped {
      .legend-item {
        .mat-mdc-list-item-icon,
        .mat-mdc-list-item-meta {
          align-self: center;
          margin-top: 0;
        }

        .mat-mdc-list-item-icon {
          --mat-icon-color: #{map.get($theme, 'foreground', 'text')};
        }

        .mat-mdc-list-item-meta {
          --mat-icon-color: #{map.get($theme, 'emphasis-md')};
        }
      }
    }
  }
}
