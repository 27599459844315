@use '@angular/material' as mat;

@mixin workflow-icon-component-theme($theme) {
  $primaryPalette: map-get($theme, primary);
  $primary: mat.get-color-from-palette($primaryPalette);
  $successStates: map-get($theme, success-states);
  $errorStates: map-get($theme, error-states);
  $success: map-get($successStates, base);
  $error: map-get($errorStates, base);

  .task-icon {
    color: mat.get-contrast-color-from-palette($primaryPalette, 500);
  }

  .blue {
    fill: $primary;
  }

  .green {
    fill: $success;
  }

  .gray {
    fill: rgba(0, 0, 0, 0.6);
  }

  .red {
    fill: $error;
  }
}
